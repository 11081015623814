import { FormInput, PrimaryButton } from '@systemeio/ui-shared'
import { createDomain } from 'modules/profile/custom-domains-settings/api/custom-domain-api'
import {
  CustomDomainsCreateInterface,
  CustomDomainsSettingsInterface,
} from 'modules/profile/custom-domains-settings/types/custom-domains-settings-interface'
import React, { useState } from 'react'
import CreateModal from 'shared/components/create-modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import AddIcon from 'shared/icons/add-icon'
import { fetchWithErrors } from 'shared/utils/fetch-with-errors'
import { getDomainInfoLink } from 'shared/utils/get-domain-info-link'

interface CreateDomainErrorsInterface {
  domainName: string
}

const defaultErrors: CreateDomainErrorsInterface = {
  domainName: '',
}
const defaultValues: CustomDomainsCreateInterface = {
  domainName: '',
}

interface CreateDomainModalInterface {
  createDomainWithUpdate: (domain: CustomDomainsSettingsInterface) => Promise<void>
}

const CreateDomainModal = ({ createDomainWithUpdate }: CreateDomainModalInterface) => {
  const { user } = useUser()
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)
  const [errors, setErrors] = useState<CreateDomainErrorsInterface>(defaultErrors)
  const afterLeave = () => {
    setErrors(defaultErrors)
  }
  const onClose = () => setOpened(false)

  const onCreate = async (data: CustomDomainsCreateInterface) => {
    await fetchWithErrors(async () => {
      const response = await createDomain(data)
      await createDomainWithUpdate(response.data)
    }, setErrors)
  }
  return (
    <>
      <PrimaryButton
        disabled={!user}
        className="flex justify-between items-center"
        onClick={() => setOpened(true)}
      >
        <AddIcon />
        <span className="hidden sm:flex">{t('dashboard.settings.custom_domains.add_domain')}</span>
      </PrimaryButton>
      <CreateModal
        fullCaption={t('dashboard.settings.custom_domains.add_domain')}
        caption={t('global.domain')}
        opened={opened}
        onClose={onClose}
        afterLeave={afterLeave}
        defaultValues={defaultValues}
        creatable={[
          {
            field: 'domainName',
            onRender: (value, onChange) => (
              <FormInput
                key={'domainName-create'}
                label={t('dashboard.settings.custom_domains.domain_name')}
                value={value}
                onChange={e => onChange(e.target.value)}
                error={errors.domainName}
                description={
                  <a
                    className={'primary-link'}
                    href={getDomainInfoLink(user?.dashboardLocale)}
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    {t('dashboard.settings.custom_domains.add_domain_description')}
                  </a>
                }
              />
            ),
          },
        ]}
        onCreate={onCreate}
        requiredFields={['domainName']}
      />
    </>
  )
}

export default CreateDomainModal
