import { NeutralButton } from '@systemeio/ui-shared'
import { useCustomDomainPageApi } from 'modules/profile/custom-domains-settings/hooks/use-custom-domain-page-api'
import React, { useState } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface DomainLoginPageButtonProps {
  domainId: number
}

const DomainNotFoundPageButton = ({ domainId }: DomainLoginPageButtonProps) => {
  const { t } = useLocoTranslation()

  const { get404PageUrl } = useCustomDomainPageApi(domainId)

  const [isFetching, setIsFetching] = useState(false)

  return (
    <NeutralButton
      type={'button'}
      isFetching={isFetching}
      onClick={async () => {
        try {
          setIsFetching(true)
          const responseData = await get404PageUrl()
          window.location.assign(responseData.loginPageUrl)
        } catch (e) {
        } finally {
          setIsFetching(false)
        }
      }}
    >
      {t('dashboard.settings.custom_domains.not_found_page_edit')}
    </NeutralButton>
  )
}

export default DomainNotFoundPageButton
