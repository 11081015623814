import {
  FileSelectorModal,
  FileSourceEnum,
  FileTypeEnum,
  FileUploaderItem,
} from '@systemeio/file-manager'
import { FieldLabel, FieldLabelProps } from '@systemeio/ui-shared'
import React, { ComponentProps } from 'react'
import { FieldErrorAndDescription } from '../form/field-error-and-description'
import { ImageUploaderNew } from './image-uploader-new'

interface Props extends FieldLabelProps {
  value?: { path: string } | null
  source: FileSourceEnum
  error?: string
  labelImageUploader?: React.ReactNode
  aspectRatio?: number
  previewClassName?: string
  dropZoneClassName?: string
  onSelect: ComponentProps<typeof FileSelectorModal>['onSelect']
  onRemove: () => void
}

export const ImageUploaderWithPreviewImage = ({
  value,
  source,
  error,
  label,
  labelImageUploader,
  required,
  aspectRatio = NaN,
  labelClassName,
  previewClassName,
  dropZoneClassName,
  onSelect,
  onRemove,
}: Props) => {
  return (
    <div className={'flex gap-1 flex-col'}>
      <FieldLabel label={label} required={required} labelClassName={labelClassName} />

      {value && (
        <>
          <FileUploaderItem
            file={{
              id: 0,
              path: value.path,
              size: 0,
              type: FileTypeEnum.Image,
              filename: '',
              thumbnail: value.path,
            }}
            showDeleteButton
            className={previewClassName}
            onDeleteFile={onRemove}
          />

          <FieldErrorAndDescription error={error} />
        </>
      )}

      <ImageUploaderNew
        showDropZone={!value}
        label={labelImageUploader}
        source={source}
        onSelect={onSelect}
        error={error}
        dropZoneClassName={dropZoneClassName}
        imageCropperModalProps={{
          imageCropperProps: { aspectRatio },
        }}
      />
    </div>
  )
}
