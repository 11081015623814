import {
  FileSelectorModal,
  FileSelectorModalProps,
  FileSourceEnum,
  FileTypeEnum,
  FileUploaderDropZone,
  FileUploaderDropZoneProps,
} from '@systemeio/file-manager'
import { ComponentProps, PropsWithChildren, useRef, useState } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface DropZoneProps
  extends Pick<
    FileUploaderDropZoneProps,
    | 'icon'
    | 'label'
    | 'acceptTypes'
    | 'variant'
    | 'error'
    | 'isPreFetching'
    | 'disabled'
    | 'className'
    | 'dropZoneClassName'
    | 'labelClassName'
  > {}

type ImageCropperModalProps = NonNullable<
  FileSelectorModalProps['fileUploaderModalProps']
>['imageCropperModalProps']

interface Props extends PropsWithChildren, DropZoneProps {
  source: FileSourceEnum
  imageCropperModalProps?: ImageCropperModalProps
  showDropZone?: boolean
  onSelect: ComponentProps<typeof FileSelectorModal>['onSelect']
}

const allowedTypes = [FileTypeEnum.Image, FileTypeEnum.ImageOther]

const initialSearchFilters = {
  name: '',
  source: [],
  type: allowedTypes,
}

export const ImageUploaderNew = ({
  icon,
  label,
  acceptTypes = allowedTypes,
  variant,
  error,
  isPreFetching,
  disabled,
  children,
  showDropZone = true,
  className,
  dropZoneClassName,
  labelClassName,
  source,
  imageCropperModalProps,
  onSelect,
}: Props) => {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)
  const uploaderRef = useRef() as NonNullable<
    FileSelectorModalProps['fileUploaderModalProps']
  >['uploaderRef']
  const fileSelectorRef = useRef() as FileSelectorModalProps['fileSelectorRef']

  return (
    <>
      {showDropZone && (
        <FileUploaderDropZone
          icon={icon}
          label={label ?? t('global.select_image')}
          acceptTypes={acceptTypes}
          variant={variant}
          error={error}
          isPreFetching={isPreFetching}
          disabled={disabled}
          className={className}
          dropZoneClassName={dropZoneClassName}
          labelClassName={labelClassName}
          openNativeWindow={false}
          onClick={() => {
            setOpened(true)
          }}
          onSelectFile={(file: File) => {
            setOpened(true)
            setTimeout(() => {
              fileSelectorRef?.current.setFileUploadModalOpen(true)
              uploaderRef?.current?.setWaitingUploadFiles(prev => [...prev, { file }])
            })
          }}
        >
          {children}
        </FileUploaderDropZone>
      )}

      <FileSelectorModal
        opened={opened}
        uploadingFromSource={source}
        fileSelectorRef={fileSelectorRef}
        disabledFileTypeSelect
        initialSearchFilters={initialSearchFilters}
        fileUploaderModalProps={{
          uploaderRef,
          imageCropperModalProps: {
            isRounded: imageCropperModalProps?.isRounded,
            imageCropperProps: {
              aspectRatio: imageCropperModalProps?.imageCropperProps?.aspectRatio ?? NaN,
            },
          },
        }}
        onSelect={file => {
          setOpened(false)
          onSelect(file)
        }}
        onClose={() => setOpened(false)}
      />
    </>
  )
}
